export const format = {

  methods: {
    formatDateDisplay (date) {
      return date ? this.moment(date).format(this.$dateConfig.date_display) : ''
    },

    formatDateTimestamp (date) {
      return date ? this.moment.utc(date).local().format(this.$dateConfig.timestamp) : ''
    },

    formatCurrencyDisplay (value) {
      return parseFloat(value).toFixed(2)
    },

    formatItemPrices (item, formatFields = ['sale_price', 'retail_price', 'net_unit_cost']) {
      for (const field of formatFields) {
        item[field] = this.formatCurrency(item[field])
      }
      return item
    },

    formatCurrency (value) {
      if (!value && value !== 0) return null 
      return ((value * 100) / 100).toFixed(2) 
    },

    priceValidator(prec, scale) {
      return (value) => {
        let v = `${value}`
        if (!v.includes('.')) v = `${v}.00`
        const [ints, decimals] = v.split('.')
        return (ints.length <= prec && decimals.length <= scale) || 'Invalid amount'
      }
    },
    
    isValidPrice(value) {
      const validator = this.priceValidator(5, 2)
      if (value) {
        return validator(value)
      }
      return true
    }
  }
  
}