<!-- View controlling the ad plan home screen -->
<template>
  <v-card flat class="pb-0 mb-0 pt-0" min-width='100%' v-resize="onResize">
    <v-container flat class="mb-0 px-0 pb-0 pt-0" fluid>
      <!-- Ad plan data table, populated by paginated API call -->
      <v-data-table 
        style="cursor:pointer" 
        :height="tableHeight" 
        :loading="loading" 
        :headers="headers" 
        :items.sync="ads"
        :items-per-page="pageSize" 
        class="table" 
        :class="table" 
        loader-height="2" 
        dense hide-default-footer
        v-model="selected" 
        item-key="adId" 
        color="#fff" 
        fixed-header 
        loading-text="Loading Ads... Please Wait"
        no-results-text="No Matching Ads Found" 
        no-data-text="No Matching Ads Found" 
        sort-desc sort-by="ad_start_date"
        @click:row="viewFeatures">
        <!-- Ad plan table slot overrides, including top toolbar where search functionality is -->
        <template #top>
          <v-toolbar flat color="toolbar" style="cursor:default;">
            <v-row align="center">
              <!--Ad Group Select-->
              <v-col md="4" lg="2" xl="2">
                <v-autocomplete
                  :class="body" 
                  v-model="defaultParty" 
                  :items="parties" 
                  item-text="party_name"
                  item-value="party_id" 
                  return-object 
                  background-color="input" 
                  placeholder="Select Party"
                  :label="partyLabelText" 
                  :loading="loading"
                  autocomplete="off" 
                  outlined
                  dense 
                  style="height: 40px;"
                  prepend-inner-icon="mdi-filter-outline"
                  :menu-props="{ maxHeight: 260, rounded: '' }" @change="getAdList">
                </v-autocomplete>
              </v-col>
              <!--Search Boxes-->
              <v-col md="10" lg="5" class="ml-5 align-center pa-0">
                <template>
                  <v-row class="align-center d-flex">
                    <!-- First Column: Autocomplete -->
                    <v-col cols="3" class="pa-0 d-flex align-center">
                      <v-autocomplete
                        dense
                        flat
                        solo
                        placeholder="Search By"
                        :items="searchParams"
                        item-text="title"
                        return-object
                        v-model="search.key"
                        class="search-field"
                        auto-select-first
                        @change="onSearchKeyChange"
                        style="height: 40px; border-top-right-radius: 0; border-bottom-right-radius: 0;"
                        :menu-props="{ maxHeight: 260, rounded: '' }"
                        outlined
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content style="font-size: 15px;">
                            {{ item.title }}
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <!-- Second Column: Search Field -->
                    <v-col cols="8" class="pa-0 d-flex align-center">
                      <template v-if="isStatusSearch">
                        <v-select
                          dense
                          solo
                          flat
                          outlined
                          :items="statusParams"
                          v-model="search.value"
                          label="Select Status"
                          item-text="description"
                          item-value="param"
                          @keydown.enter="searchAds"
                          class="status-select"
                          clearable
                          :disabled="!search.key"
                          @click:clear="clearSearch" 
                          style="height: 40px; border-radius: 0;"
                        >
                        </v-select>
                      </template>
                      <template v-else>
                        <v-text-field
                          dense
                          flat
                          solo
                          outlined
                          :placeholder="searchPlaceholder"
                          v-model="search.value"
                          class="text-search-field"
                          :disabled="!search.key"
                          @keydown.enter="searchAds"
                          clearable
                          @click:clear="clearSearch()"
                          style="height: 40px; border-radius: 0;"
                        >
                        </v-text-field>
                      </template>
                    </v-col>

                    <!-- Third Column: Button -->
                    <v-col cols="1" class="pa-0 d-flex align-center">
                      <v-btn
                        depressed
                        dense
                        :loading="loading"
                        :disabled="!search.key || !search.value && !searched"
                        color="accent"
                        style="height: 40px; border-top-left-radius: 0px; border-bottom-left-radius: 0px; border: 1px solid #9e9e9e; border-left: 0px;"
                        @click="searchAds"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-divider />
        </template>
        <template #[`item.ad_start_date`]="{ item }">
          {{ formatDateDisplay(item.ad_start_date) }}
        </template>
        <template #[`item.ad_end_date`]="{ item }">
          {{ formatDateDisplay(item.ad_end_date) }}
        </template>
        <template #[`item.audit.updated_on`]="{ item }">
          {{ formatDateTimestamp(item.audit.updated_on) }}
        </template>
        <template #[`item.clone`]="{ item }">
          <v-btn v-if="item.cloned_from_ad_id" icon dense @click.stop='viewClone(item.cloned_from_ad_id)'>
            <v-icon v-if="item.cloned_from_ad_id">mdi-check</v-icon>
          </v-btn>
        </template>
        <template #[`item.submit_order_grid`]="{ item }">
          <v-icon v-if="item.order_grid_submitted">mdi-check</v-icon>
        </template>
        <template #[`item.status`]="{ item }">
          <span v-if="!item.is_processing">
            <v-chip @click.stop small dark :color="getColor(item.status)">
              <v-icon :disabled="prevStatusDisabled(item.status)" left
                @click="component = 'AdStatusEdit', selectedAd = item, modal = true, direction = 'prev'">
                mdi-chevron-left
              </v-icon>
              <span style="display: inline-block; margin: 0 auto; width: 70px; text-align: center">
                {{ item.status }}
              </span>
              <v-icon :disabled="nextStatusDisabled(item.status)" right @click="component = 'AdStatusEdit',
              selectedAd = item, modal = true, direction = 'next'">
                mdi-chevron-right
              </v-icon>
            </v-chip>
          </span>
          <span v-else>
            <v-chip @click.stop small dark color="teal lighten-1">
              <span style="display: inline-block; margin-right: 0 auto; width: 95px; text-align: center;">
                Processing
              </span>
              <span>
                <v-progress-circular indeterminate color="white" :size="15" :width="2"
                  class="mr-2"></v-progress-circular>
              </span>
            </v-chip>
          </span>
        </template>
        <!-- Actions menu contains several modals including notes and the ability to look at/generate an ad planner document -->
        <template #[`item.actions`]="{ item }">
          <AdActions :ad="item" :adParty="defaultParty" :allowRefresh.sync="allowRefresh" @refresh="getAdList" />
        </template>
        <template #[`item.info`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn v-on="on" icon @click.stop="copyID(item)">
                <v-icon>mdi-information</v-icon>
              </v-btn>
            </template>
            <span>{{ item.id }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <Component 
        :key="componentKey" 
        :is="component" 
        :id='id' 
        :value.sync="modal" 
        :selectedAd="selectedAd" 
        :direction="direction" 
        :defaultParty='defaultParty'
        :getAdList="getAdList" 
        :parties="parties"
        :layouts="layouts"
        @refresh="getAdList"
      />
      <v-toolbar dense flat color="#fafafa">
        <v-row align="center" justify="end">
          <v-col cols="2" style="max-width:150px;">
            <v-select class="mt-8" dense :items="pageSizes" label="Items Per Page" select v-model="pageSize"
              :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
            </v-select>
          </v-col>
          <v-btn icon large @click="previous()" :disabled="disablePrevious" class="mx-2">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <small>Page {{ page }}</small>
          <v-btn icon large @click="next()" :disabled="disableNext" class="mx-2">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </v-toolbar>
      <v-fab-transition>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn v-on="on" :style="floatingButton" absolute bottom right fab class="primary"
              @click="component='AdView', modal=true" :disabled="!defaultParty">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Create</span>
        </v-tooltip>
      </v-fab-transition>
    </v-container>
  </v-card>
</template>

<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { format } from '@/mixins/format'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
import { displayAlert } from '@/mixins/alert'
export default {
  name: 'AdList',
  components: {
    AdActions: () => import('@/components/ads/ad-actions'),
    AdStatusEdit: () => import('@/components/ads/ad-status-edit'),
    AdView: () => import('@/components/ads/ad-view'),
  },
  mixins: [displayHelpers, format, userAccess, utils, displayAlert],
  data() {
    return {
      ads: [],
      component: '',
      componentKey: 0,
      defaultParty: {},
      direction: '',
      disableNext: false,
      disablePrevious: true,
      doc: {},
      editMenu: false,
      fab: false,
      id: '',
      isAd: true,
      isStatusSearch: null,
      loading: true,
      search: { value: null },
      searched: false,
      menu: false,
      modal: false,
      newMenu: false,
      offset: true,
      page: 1,
      pageSize: 50,
      pageSizes: [50, 100, 200],
      adPage: 1,
      upstream: [],
      selected: [],
      selectedAd: {},
      tableHeight: '',
      tags: { 'ad_id': '' },
      layouts: [],
      disableCreateButton: false,
      timer: '',
      allowRefresh: true,
      searchPlaceholder: 'Select Search Field'
    }
  },
  computed: {
    partyLabelText() {
      return this.useAdGroups ? 'Ad Group' : 'Store'
    },
    buttonDisabled() {
      return !this.search.key || !this.search.value
    },
    partyType() {
      return this.defaultParty.party_type
    },
    parties() {
      if(this.useAdGroups) {
        if (this.limitAccessByRelatedAdGroups) {
          return this.$store.getters.adGroups.filter(adGroup => this.userAdGroupIds.includes(adGroup?.party_id))
        } else {
          return this.$store.getters.adGroups
        }
      } else {
        return this.$store.getters.userStores
      }
    },
    searchParams() {
      return [
        { title: 'Status', param: 'statuses', description: 'Status' },
        { title: 'Item ID ', param: 'item_id', description: 'Item ID' },
        { title: 'Promo Number', param: 'promo_number', description: 'Promo Number' },
        { title: 'Promo Name ', param: 'promo_name', description: 'Promo Name' },
        { title: 'Category', param: 'category', description: 'Category' },
        { title: 'Sub Category', param: 'subcategory', description: 'Sub Category' },
        { title: 'UPC', param: 'upc', description: 'UPC' },
        { title: 'Ad Name', param: 'ad_name', description: 'Ad Name' },
        { title: 'Ad ID', param: 'ad_id', description: "Ad ID" },
        { title: 'Start Date', param: 'start_date', description: "Start Date: MM/DD/YYYY" },
      ]
    },
    statusParams() {
      return [
        { description: 'Approved', param: 'Approved' },
        { description: 'Submitted', param: 'Submitted' },
        { description: 'Draft', param: 'Draft' },
        { description: 'Rejected ', param: 'Rejected' },
        { description: 'Exception ', param: 'Exception' },

      ]
    },
    formattedAdDate() {
      return this.adDateFilter ? this.moment(this.adDateFilter).format(this.$dateConfig.date_display) : ''
    },
    headers() {
      return [
        { sortable: false, class: 'accent' },
        { text: 'Name', align: 'left', sortable: true, value: 'ad_name', class: 'accent white--text' },
        { text: 'Start Date', align: 'center', sortable: true, value: 'ad_start_date', class: 'accent white--text' },
        { text: 'End Date', align: 'left', sortable: true, value: 'ad_end_date', class: 'accent white--text' },
        { text: 'Last Update User', align: 'left', sortable: true, value: 'audit.updated_by', class: 'accent white--text', width: '10%' },
        { text: 'Last Update Date', align: 'left', sortable: true, value: 'audit.updated_on', class: 'accent white--text', width: '10%' },
        { text: 'Status', align: 'center', sortable: true, value: 'status', class: 'accent white--text', width: '10%' },
        { text: 'Ad ID', align: 'center', sortable: false, class: 'accent white--text', value: 'info' },
        { text: 'Clone', align: 'center', sortable: false, class: 'accent white--text', value: 'clone' },
        { text: 'Order Grid Submitted', align: 'center', sortable: false, class: 'accent white--text', value: 'submit_order_grid' },
        { text: 'Actions', align: 'center', sortable: false, value: 'actions', class: 'accent white--text', width: '5%' },
        { sortable: false, class: 'accent' },
      ]
    }
  },
  watch: {  
    modal: {
      immediate: true,
      handler() {
        this.componentKey += 1
        if (!this.modal) this.selectedAd = {}
      }
    },
    '$route': {
      handler() {
        if (this.$route.query.search) {
          this.search.key = JSON.parse(this.$route.query.searchKey)
          this.search.value = this.$route.query.searchValue
          this.page = parseInt(this.$route.query.page, 10) || 1
          this.searchAds()
        }
      },
      immediate: true
    },
    defaultParty: {
      handler(newValue) {
        if (newValue) {
          sessionStorage.setItem('ad_party', JSON.stringify(newValue))
          this.updateDC(newValue.party_id)
        }
      },
      deep: true
    },
    parties: {
      handler(newValue) {
        let index = 0
        if (this.$auth.tenant == 'pricechopper') {
          index = this.parties.findIndex(obj => obj.name === 'PCE Ad Group');
        }
        this.defaultParty = sessionStorage.ad_party ? JSON.parse(sessionStorage.ad_party) : newValue[index]
        this.getAdList()
      }
    }
  },
  async created() {
    this.tableHeight = window.innerHeight - 188
    localStorage.removeItem('selected_ad')
    if (localStorage.ad_date_filter) {
      this.adDateFilter = localStorage.getItem('ad_date_filter')
    }
    if (this.parties.length === 0) {
      await this.$store.dispatch('initParties')
    }
    let index = 0
    if (this.$auth.tenant == 'pricechopper') {
      index = this.parties.findIndex(obj => obj.name === 'PCE Ad Group');
    }
    this.defaultParty = sessionStorage.ad_party ? JSON.parse(sessionStorage.ad_party) : this.parties[index]
    const persistedFilters = sessionStorage.getItem('ad_search_filters')
    if (persistedFilters) {
      const savedFilters = JSON.parse(persistedFilters)
      if (savedFilters.value.trim() != '') {
        this.search.key = savedFilters.key
        this.search.value = savedFilters.value
        this.page = savedFilters.page || 1
        if (this.search.key && this.search.key.description == 'Status') {
          this.isStatusSearch = true
        }
        else {
          this.isStatusSearch = false
        }
        await this.searchAds()
      }
    }
    else {
      this.getAdList()
    }
    this.getLayouts()
    this.timer = setInterval(this.refresh, 60000);
    // this.searchDocs()
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    prevStatusDisabled(status) {
      return ['Draft', 'Rejected', 'Error', 'Exception'].includes(status)
        || (status === 'Approved' && !this.hasStatusAdminAccess)
    },
    nextStatusDisabled(status) {
      if(this.$auth.tenant === 'pricechopper' && !this.hasStatusAdminAccess) {
        return true
      }
      return status === 'Approved'
    },
    isValidDate(d) {
      return (!isNaN(Date.parse(d)))
    },
    copyID(ad) {
      navigator.clipboard.writeText(ad.id)
      this.$store.dispatch('setSnackbar', { status: 'success', text: `Copied!` })
    },
    downloadLink(link) {
      window.open(link.ad_planner_url)
    },
    onResize() {
      this.tableHeight = window.innerHeight - 215
    },    
    getColor(status) {
      switch (status) {
        case 'Rejected':
          return 'red'
        case 'Draft':
          return 'amber'
        case 'Submitted':
          return 'green'
        case 'Approved':
          return 'primary'
        case 'Error':
          return 'black'
      }
    },
    async updateDC(party_id) {
      const results = await this.getDistributionCenter(party_id)
      this.defaultParty.distribution_center = results.distribution_center
      this.defaultParty.dc_id = results.dc_id
    },
    refresh () {
      if (this.allowRefresh && !this.modal) {
        if (this.searched) {
          const persistedFilters = sessionStorage.getItem('ad_search_filters')
          if (persistedFilters) {
            const savedFilters = JSON.parse(persistedFilters)
            if (savedFilters.value.trim() != '') {
              this.search.key = savedFilters.key
              this.search.value = savedFilters.value
              if (this.search.key && this.search.key.description == 'Status') {
                this.isStatusSearch = true
              }
              else {
                this.isStatusSearch = false
              }
              this.searchAds()
            }
          }          
        } 
        else {
          this.getAdList()
        }
      }
    },
    async getAdList() {
      if (this.defaultParty?.party_id) {
        this.ads = []
        this.searched = false
        this.loading = true
        const limit = this.pageSize
        const offset = (this.page - 1) * this.pageSize
        try {
          await this.$Ads.getAdList(this.defaultParty.party_id, this.adDateFilter, limit, offset)
            .then(res => {
              this.ads = res.data
              this.ads.length < this.pageSize
                ? this.disableNext = true
                : this.disableNext = false

              offset === 0
                ? this.disablePrevious = true
                : this.disablePrevious = false

              // this.searchDocs()
            })
        } catch (err) {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Ad List due to ${err?.response?.data?.message || err.message}` })
        } finally {
          this.loading = false
        }
      }
    },
    async getLayouts() {
      await this.$Layouts.getLayouts()
        .then(res => {
          this.layouts = res.data
        }).catch(err => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Layouts due to ${err?.response?.data?.message || err.message}` })
        })
    },
    async searchAds() {
      this.loading = true

      let key = this.search.key?.param;
      let value = this.search.value;

      if (!key || !value || value.trim() === '') {
        this.clearSearch()
        this.getAdList()
        return;
      }

      sessionStorage.setItem(
        'ad_search_filters',
        JSON.stringify({ key: this.search.key, value: this.search.value, page: this.page })
      );

      if (!this.searched) {
        this.adPage = this.page
        this.page = 1
      }

      const offset = (this.page - 1) * this.pageSize;
      const params = {
        limit: this.pageSize,
        offset: offset,
        party_id: this.defaultParty?.party_id
      };

      try {
        switch (key) {
          case 'statuses':
            params.statuses = value.toString();
            break;
          case 'start_date':
            if (!this.isValidDate(value)) {
              this.$store.dispatch('setSnackbar', { status: 'error', text: 'Invalid date format. Use MM/DD/YYYY.' });
              this.loading = false;
              return;
            }
            params.start_date = Date.parse(value);
            break;
          default:
            params[key] = value;
        }
        const res = await this.$Ads.search(this.defaultParty.party_id, params);
        if (res.data) {
          this.ads = res.data;
          this.disableNext = res.data.length < this.pageSize;
          this.disablePrevious = offset === 0;
        }
      } catch (err) {
        console.error('Search Error:', err);
        this.$store.dispatch('setSnackbar', {
          status: 'error',
          text: `Failed to Search Due to ${err?.response?.data?.message || err.message}`
        });
      } finally {
        this.loading = false;
        this.searched = true;
      }
    },
    onSearchKeyChange() {
      this.search.value = null; 
      if (this.search.key && this.search.key.description && this.search.key.description != 'Status') {
        this.searchPlaceholder = `Search By ${this.search.key.description}`;
        this.isStatusSearch = false
      } else if (this.search.key && this.search.key.description == 'Status') {
        this.isStatusSearch = true
      } else {
        this.searchPlaceholder = 'Select Search Field';
      }
    },
    clearSearch() {
      this.search = { key: null, value: null }
      this.searched = false
      this.isStatusSearch = false
      this.page = this.adPage
      this.getAdList()
      sessionStorage.clear()
      this.onSearchKeyChange() 
    },
    async searchDocs() {
      this.ads.forEach(ad => {
        if (ad.status == 'Submitted' || ad.status == 'Approved') {
          this.tags.ad_id = ad.id
          const params1 = {
            root_key: 'ipro_portal',
            party_ids: ad.ad_party_id,
            tags: JSON.stringify(this.tags)
          }
          this.searchLinks(params1, ad)
        }
      })
    },
    async searchLinks(params1, ad) {
      await this.$Documents.searchDocs(params1).then(res => {
        if (res.data[0]) {
          this.doc = res.data[0]
          let docID = this.doc.id
          this.assignLinks(docID, ad)
        }
      })
    },
    async assignLinks(docID, ad) {
      await this.$Documents.getFile(docID).then(res => {
        if (res.data) {
          ad.ad_planner_url = res.data.url
        }
      })
    },
    async viewClone(id) {
      await this.$Ads.getAdById(id).then(res => {
        this.$router.push({ name: 'Ad Features', params: { selectedAd: res.data, party: this.defaultParty }, query: { id: res.data.id } })
      })
    },
    previous() {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      if (this.searched) {
        this.searchAds()
      }
      else {
        this.getAdList()
      }
    },
    next() {
      this.page = this.page + 1
      if (this.searched) {
        this.searchAds()
      }
      else {
        this.getAdList()
      }
    },
    allowedAdDates: val => ![1, 2, 3, 4, 5, 6].includes(new Date(val).getDay()),
    viewAd(ad) {
      this.selectedAd = ad
      this.component = 'AdView'
      this.modal = true
    },
    viewFeatures(selectedAd) {
 
      this.$router.push({
        name: 'Ad Features',
        params: {
          selectedAd: selectedAd,
          party: this.defaultParty
        },
        query: {
          id: selectedAd.id,
          searchKey: JSON.stringify(this.search.key),
          searchValue: this.search.value,
          page: this.page
        }
      });
    }
  }
}
</script>