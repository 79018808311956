export const utils = {
  methods: {
    async getAllSettled (promises, returnErrors = false) {
      const rejected = []
      const results = await Promise.allSettled(promises)
      const fulfilled = results.flatMap(result => {
        if (result.status === 'rejected') {
          rejected.push(result)
          return []
        }
        return result.value
      })
      if (rejected.length > 0) console.log('Rejected promises:', rejected)
      if (returnErrors) {
        return { fulfilled, rejected }
      }
      return fulfilled
    },
    sortByKey (arr, key) {
      arr.sort((a, b) => {
        if (a && b) {
          if (a[key] < b[key]) return -1
          else if (a[key] > b[key]) return 1
          else return 0
        }
      })
      return arr
    },
    getUniqueByKey (arr, key) {
      if (arr?.length > 0) {
        const values = arr.map(item => item[key])
        const uniqueValues = Array.from(new Set([...values]))
        return uniqueValues.sort()
      }
      return []
    },
    async getDistributionCenter(partyID) {
      let dc_source = ''
      let dc_id = ''
      try {
        const res = await this.$PartyRelationship.getDistributionCenter(partyID)
        if (res?.data?.length > 0) {
          const primaryDC = res.data.find(r => r.from_rel_type === 'AD_GROUP_HAS_PARENT')
          if (primaryDC) {
            const sourceRes = await this.$Items.getSourceRelsByPartyId(primaryDC.to_party_id)
            if (sourceRes?.data?.length > 0) {
              const DC = sourceRes.data[0]
              dc_source = DC.source_id
              dc_id = DC.party_id
            }
          }
        }
        return { distribution_center: dc_source, dc_id: dc_id }
      } catch (err) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to load Ad Feature List due to ${err?.response?.data?.message || err.message}` })
      }
    },
  }
}